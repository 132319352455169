import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'pi-button',
  imports: [NgClass, SvgIconComponent],
  standalone: true,
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input()
  theme:
    | 'primary'
    | 'danger'
    | 'info'
    | 'outline'
    | 'inverse'
    | 'outline-danger' = 'primary';

  @Input()
  iconSrc: string = '';

  @Input()
  text: string = '';

  iconEnd = input(false);
  svgStyle = input<Record<string, any>>({});

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();
}
