<button class="pi-button" [ngClass]="theme" (click)="clicked.emit()">
  @if (iconSrc) {
    <svg-icon [src]="iconSrc" [svgStyle]="svgStyle()"></svg-icon>
  }
  <span>{{ text }}</span>

  @if (iconEnd() && iconSrc) {
    <svg-icon [src]="iconSrc" [svgStyle]="svgStyle()"></svg-icon>
  }
</button>
